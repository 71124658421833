import * as React from 'react';
import { ErrorInfo } from 'react';
import './ErrorBoundary.css';
import { logReactError } from './Services/Logging.Service';
import { ErrorPage } from './ErrorPage';

interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    logReactError(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
