import { jssPreset, StylesProvider } from '@material-ui/styles';
import { create } from 'jss';
import jssBrowserPrefix from 'jss-plugin-vendor-prefixer';
import { render } from 'react-dom';
import Modal from 'react-modal';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import { logConsoleError } from './Services/Logging.Service';
import './i18n';
import configureAxios from './axios';

window.onerror = logConsoleError;

const jss = create({
  plugins: [...jssPreset().plugins, jssBrowserPrefix()],
});

configureAxios();

const root = document.getElementById('root') as HTMLElement;
Modal.setAppElement(root);

render(
  <ErrorBoundary>
    <StylesProvider jss={jss}>
      <App />
    </StylesProvider>
  </ErrorBoundary>,
  root
);
