import * as React from 'react';

import './ErrorPage.css';

interface IProps {
  headerText?: string;
  subText?: string;
}

export const ErrorPage = ({ headerText, subText }: IProps) => {
  return (
    <div className="error-page">
      <div className="col-sm-8 offset-sm-2">
        <h1>{headerText || 'Oops! Something went wrong.'}</h1>
        <h3>{subText || "We've been notified and will fix it ASAP."}</h3>
      </div>
    </div>
  );
};
